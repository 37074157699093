import React from "react";
import {ArticleDate, ArticleMagazine, ArticleTitle, ArticleWrapper} from "./styledElements";

// NOTE: COMPONENT IS VERY SIMILAR TO SLIDE COMPONENT -> REMOVE IF SINGLE ARTICLE WON'T NEEDED
export const Article = ({magazine, title, date, link}) => (
  <ArticleWrapper>
      <ArticleMagazine>{magazine}</ArticleMagazine>
      <ArticleTitle>{title}</ArticleTitle>
      <ArticleDate>{date}</ArticleDate>
      <a href={link} target="_blank" rel="noopener noreferrer">READ FULL ARTICLE <img src="/assets/arrow.svg" alt="" /></a>
  </ArticleWrapper>
  );
