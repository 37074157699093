import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Visualizations from "./EntryContent/Visualizations";
import AllArtworksWithFilter from "./EntryContent/AllArtworksWithFilter";
import Header from "./Header";
import Footer from './Footer';
import Layout from './Layout';
import {
  Content,
  ExhibitionFold,
  ExhibitionFoldContent,
  ExhibitionFoldsWrapper,
  Spacer,
  VideoContainer,
  VideoDescription,
  VideoSection,
  VideoSectionWrapper,
} from './EntryContent/styledElements';
import Video from "./EntryContent/Video";
import {ArtworkInfoButton, ArtworkInfoButtonContainer} from "./ArtworkContent/styledElements";
import {Article} from "./EntryContent/single-article";

const Entry = ({
  artworks,
  slug,
  assetsPath,
  cdnPath,
  exhibitions,
  visualizations,
  language,
  artworkSlugs,
}) =>  {
  const singleExhibition = exhibitions.length === 1;

  return (
    <Layout noImageBg>
      <Content>
        <Header assetsPath={assetsPath}/>
      </Content>
        <ExhibitionFoldsWrapper singleExhibition={singleExhibition}>
          {exhibitions.map((exhibition) => (
            <ExhibitionFold imageBgUrl={assetsPath + '/home/'+ exhibition.home_page_image_file_name} key={exhibition.id}>
              <ExhibitionFoldContent>
                <h1>{exhibition.title}</h1>
                <h2>{exhibition.subtitle}</h2>
                <p>{exhibition.description}</p>
              </ExhibitionFoldContent>
            </ExhibitionFold>
          ))}
        </ExhibitionFoldsWrapper>
        <Content>
          <VideoSectionWrapper singleExhibition={singleExhibition}>
            {exhibitions.map((exhibition) => (
              <VideoSection
                key={exhibition.id}
              >
                <VideoDescription>
                  <h1>About <i>{exhibition.subtitle}</i> exhibition</h1>
                  <p>{exhibition.home_page_info}</p>
                  <ArtworkInfoButtonContainer>
                    <ArtworkInfoButton
                      as="a"
                      href={`${assetsPath}/documents/${exhibition.press_file_name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="primary"
                      size="short"
                      centered
                    >Press</ArtworkInfoButton>
                    <ArtworkInfoButton
                      type="secondary"
                      onClick={() => scrollTo(`#all-artworks`)}
                    >Browse catalogue</ArtworkInfoButton>
                  </ArtworkInfoButtonContainer>
                </VideoDescription>
                {exhibition.video_url && (
                  <VideoContainer>
                    <Video
                      videoUrl={exhibition.video_url}
                    />
                  </VideoContainer>
                )}
              </VideoSection>
            ))}
          </VideoSectionWrapper>
          {/* NOTE: IT will BE DISPLAYED ONLY FOR ONE EXHIBITION -> COLOURS OF MY DREAM */}
          {singleExhibition
            && exhibitions[0].id === 24
            && exhibitions[0].bio_file_name
            && (
              <VideoSection>
                <Article
                  title="Une belle surprise en galerie à Lausanne - Une Afrique qui dépasse les clichés"
                  magazine=""
                  date="3 July, 2021"
                  link={`${assetsPath}/documents/${exhibitions[0].bio_file_name}`}
                />
              </VideoSection>
          )}
        <Spacer height={30} />
          {exhibitions.map((exhibition) => (
            <Visualizations
              key={exhibition.id}
              visualizations={visualizations}
              artworks={artworks}
              slug={slug}
              assetsPath={assetsPath}
              cdnPath={`${cdnPath}/${exhibition.assets_folder}`}
              fillColor="rgba(255, 255, 255, 0.3)"
              strokeColor="rgba(255, 255, 255, 1)"
              language={language}
              hasSlider={false}
              isActive={exhibition.active}
            />
          ))}
        <AllArtworksWithFilter
          artworks={artworks}
          assetsPath={assetsPath}
          cdnPath={cdnPath}
          exhibitions={exhibitions}
          artworkSlugs={artworkSlugs}
          language={language}
        />
        {exhibitions.map((exhibition) => (
          <Footer
            key={exhibition.id}
            exhibition={exhibition}
            assetsPath={assetsPath}
            cdnPath={cdnPath}
          />
        ))}
      </Content>
    </Layout>
  )};

export default Entry;
