import styled from 'styled-components';

const ArticleMagazine = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  
  @media all and (max-width: 800px) {
    font-size: 1.3rem;
    padding-bottom: 0.5rem;
  }
`;

const ArticleTitle = styled.h1`
  display: block;
    font-size: 32px;
    line-height: 38px;
  font-weight: 400;
  @media all and (max-width: 1200px) {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }
  @media all and (max-width: 800px) {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
`;

const ArticleDate = styled.span`
  display: block;
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: 300;
    color: #3A3A3A;
    padding: 15px 0;
    
    @media all and (max-width: 800px) {
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 10px 0;
  }
`;

const ArticleWrapper = styled.div`
    a {
      display: block;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000;
      text-decoration: none;
      font-weight: 500;
      
      img {
        display: inline-block !important;
        padding-left: 10px;
        transition: all 0.2s ease-in-out;
      }
      
      &:hover {
        img {
          padding-left: 15px;
        }
      }
    }
`;

export {
    ArticleMagazine,
    ArticleDate,
    ArticleTitle,
    ArticleWrapper,
}
