import React from 'react';
import { graphql } from 'gatsby';
import Entry from "../components/Entry";

export const query = graphql`
    query GetArtworksByExhibitionsId($exhibitions: [Int!]) {
        arteiaBuild {
            artworks(where: {exhibition_id: {_in: $exhibitions}}, order_by: { id: asc }) {
                id
                artist
                artwork_title
                date_of_creation
                dimensions
                exhibition_id
                exhibitions
                image_file_name
                literature
                provenance
                signature
                technique
                external_link
            }
            exhibitions(where: {id: {_in: $exhibitions}}, order_by: { id: asc }) {
                id
                title
                subtitle
                description
                home_page_image_file_name
                home_page_info
                footer_text
                footer_phone
                video_url
                press_file_name
                bio_file_name
                assets_folder
                active
                footer_credits
            }
            visualizations(where: {exhibition_id: { _in: $exhibitions}}, order_by: { order: asc }) {
                id
                image_file_name
                visualizations_artworks {
                    id
                    artwork_id
                    coordinates
                }
            }
        }
    }
`;

const EntryTemplate = ({ data, pageContext }) => {
  const { artworks, exhibitions, visualizations } = data.arteiaBuild;
  const { assetsPath, cdnPath, artworkSlugs, language, slug } = pageContext;

  return (
    <Entry
      artworks={artworks}
      exhibitions={exhibitions}
      visualizations={visualizations}
      assetsPath={assetsPath}
      cdnPath={cdnPath}
      language={language}
      slug={slug}
      artworkSlugs={artworkSlugs}
    />
  );
};

export default EntryTemplate;
